import {
  ApprovalStatusReponse,
  CommonGetListResponse,
  Group,
  PortList,
  RecordType,
  SupplierList,
  UserPIC,
  VesselItem,
} from 'shared/types'

export interface DocumentNoRes {
  id: string
  docNumber: string
}

export interface useCommonState {
  vesselCodes: VesselItem[]
  documentNoRes: DocumentNoRes
  suppliers: SupplierList[]
  approvalStatus: ApprovalStatusReponse[]
  portCodes: PortList[]
  commonCodes: object
  files: Array<object>
  recordTypes: RecordType
  unreadCounts: object
  groups: Group[]
  users: UserPIC[]
  portCodesV2: CommonGetListResponse<PortList>
  suppliersV2: CommonGetListResponse<SupplierList>
  noticeRes: CommonGetListResponse<Board>
}

export interface FormPassword {
  id: string
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export enum VALIDATE_TYPE {
  ID = 'ID',
  CURRENT_PASSWORD = 'CURRENT_PASSWORD',
  NEW_PASSWORD = 'NEW_PASSWORD',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  CURRENT_OLD_PASSWORD_SAME = 'CURRENT_OLD_PASSWORD_SAME',
}

export enum LOGIN_MODAL_TYPE {
  MEM = 'MEMBERSHIP',
  ISO = 'ISO',
  ETH = 'ETHICS',
  GUI = 'GUIDELINE',
}

export interface Board {
  boardSequence?: number
  transmitYmd?: string
  titleName: string
  drafterEmailAddress?: string
  contentDescription?: string
  vmsFileId?: number
  confidentialFlag?: string | boolean
  boardCode: string
  drafterEmployeeTitle?: string
  drafterEnglishName?: string
  drafterKoreanName?: string
  count?: number
  moduleCode?: string
}

export interface Bid {
  seq: string
  cssType: string
  bidgAdmSeq: string
  bidgCagyCd: string
  bidgNo: string
  bidgTtlNm: string
  bidgStYmd: string
  bidgEndYmd: string
  bidgStusYmd: string
  bidgStusCd: string
  bidgCagyNm: string
}

export interface LastestInfo {
  quotation: LastestItem[]
  order: LastestItem[]
  claim: LastestItem[]
  invoice: LastestItem[]
}

export interface LastestItem {
  kindNm: string
  kindCd: string
  rfqNo: string
  clmNo: string
  vslCd: string
  rfqStYmd: string
  purOrdNo: string
  purOrdYmd: string
  vmsCompanyCode: string
  supplierId: string
}

export interface NewEBid {
  bidList: Bid[]
}

export interface ProgressCount {
  quotation: number
  order: number
  claim: number
  invoice: number
}
